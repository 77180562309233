import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { DateTime } from "luxon";
import { 
  QuestionCircleOutlined,
  ClockCircleOutlined,
  UserOutlined,
  PhoneOutlined,
  FileTextOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import "./AppoinmentCalender.css";
import { doGet, doPost, doPatch, doDelete } from "../../../../API/apis";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import { useOutletContext } from "react-router-dom";
import BaseDatePicker from "../../../../components/BaseDatePicker/BaseDatePicker";
import luxonPlugin from "@fullcalendar/luxon3";
import {
  Button,
  Modal,
  Input,
  Form,
  Row,
  Col,
  Select,
  TimePicker,
  Avatar,
  Checkbox,
  Tooltip,
  AutoComplete,
  Popover,
  Tag
} from "antd";
import TimeClock from "../../../../components/TimeClock/TimeClock";
import moment from "moment-timezone";
import Notification from "../../../../components/Notification/Notification";
import showConfirmModal from "../../../../components/ModalConfirm/ModalConfirm";
import { useAppointmentSearchServices, useSearchPatient } from "../../../../API/apiUtils";
import FormatDate from "../../../../components/FormatDate/FormatDate";
import AppointmentDatePicker from "../../../../components/BaseDatePicker/AppointmentDatePicker";
import { phoneValidator } from "../../../../utils/validator";
import { AppointmentStatus } from "../../../../components/GlobalComponents/StatusLabels";

const { Option } = Select;
const TIME_FORMAT = "h:mm a";

const SUCCESS_MESSAGES = {
  update: "Appointment Updated Successfully",
  add: "Appointment Created Successfully",
};

const DURATION_OPTIONS = [
  { value: "15", label: "15 minutes" },
  { value: "30", label: "30 minutes" },
  { value: "45", label: "45 minutes" },
  { value: "60", label: "60 minutes" },
];

// Add a helper function to format the appointment status with appropriate color
const getStatusTag = (status) => {
  const statusColors = {
    'Scheduled': 'blue',
    'Rescheduled': 'orange',
    'Completed': 'green',
    'Cancelled': 'red',
    'No Show': 'purple'
  };


  
  return <Tag color={statusColors[status] || 'blue'}>{status}</Tag>;
};

// Helper function to create a correct time object from a date
// This ensures 12 PM is properly handled and not converted to 12 AM
const createTimeObject = (date, timezone) => {
  let timeObj;
  
  if (date instanceof Date) {
    // Handle JavaScript Date object
    const dateTime = DateTime.fromJSDate(date, { zone: timezone });
    timeObj = {
      hour: dateTime.hour,
      minute: dateTime.minute,
      displayTime: dateTime.toFormat("hh:mm a"),
      dateTime: dateTime
    };
  } else if (moment.isMoment(date)) {
    // Handle Moment object
    const momentDate = date.clone().tz(timezone);
    timeObj = {
      hour: momentDate.hours(),
      minute: momentDate.minutes(),
      displayTime: momentDate.format("h:mm a"),
      dateTime: momentDate
    };
  } else if (date instanceof DateTime) {
    // Handle Luxon DateTime object
    timeObj = {
      hour: date.hour,
      minute: date.minute,
      displayTime: date.toFormat("hh:mm a"),
      dateTime: date
    };
  } else {
    // Fallback for other types
    console.warn("Unknown date type provided to createTimeObject");
    const now = DateTime.now().setZone(timezone);
    timeObj = {
      hour: now.hour,
      minute: now.minute,
      displayTime: now.toFormat("hh:mm a"),
      dateTime: now
    };
  }
  
  // Create a dayjs object with the correct hour using 24-hour format
  const dayjsTime = dayjs()
    .hour(timeObj.hour)
    .minute(timeObj.minute)
    .second(0);
    
  return {
    ...timeObj,
    dayjsTime
  };
};

function AppointmentCalendar() {
  const [form] = Form.useForm();
  const calendarRef = useRef(null);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [patientName, setPatientName] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);
  const [appointmentWith, setAppointmentWith] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [isNotRegistered, setIsNotRegistered] = useState(false);
  const [selectedProvider, setSelectedDProvider] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [currentView, setCurrentView] = useState("dayGridMonth");
  
  const { options, handleSearchServices } = useAppointmentSearchServices();
  const { options: nameOptions, handleSearchPatient } = useSearchPatient(({ fullName }) => fullName);
  const [officeDateFormat, officeTimeFormat, officeTimezone] = useOutletContext();
  const { officeSetting, providers } = useStateGlobalContext();
  
  // Extract operating hours once
  const operatingHours = officeSetting?.operating_hrs?.[0] || {};
  const calendarOpeningTime = operatingHours?.start_time || "08:00";
  const calendarClosingTime = operatingHours?.end_time || "18:00";

  // Define getAppointments first before it's used in other functions
  const getAppointments = useCallback(async () => {
    if (!currentView?.startStr) return;
    
    setLoading(true);
    try {
      // Convert ISO String to Date Object and format dates
      const startDate = new Date(currentView.startStr).toISOString().slice(0, 10);
      const endDate = new Date(currentView.endStr).toISOString().slice(0, 10);

      const response = await doGet(
        `/reports/appointments/?start_date=${startDate}&end_date=${endDate}`
      );
      
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
    } finally {
      setLoading(false);
    }
  }, [currentView]);

  // Memoize patient name to avoid recalculation
  const patient_name = useMemo(() => {
    if (clientDetails) {
      return `${clientDetails.first_name || ''} ${clientDetails.middle_name || ''} ${clientDetails.last_name || ''}`.trim();
    }
    return '';
  }, [clientDetails]);

  // Memoize events to avoid recalculation on every render
  const events = useMemo(() => {
    return data?.map((appointment) => {
      const {
        id,
        first_name,
        middle_name,
        last_name,
        dob,
        gender,
        clinic,
        contact,
        appointment_with,
        appointment_date,
        service,
        visit_reason,
        duration,
        patient_id,
      } = appointment;

      const endTime = dayjs(appointment_date);
      const newEndTime = endTime.add(duration, "minute");
      const newTimeString = newEndTime.toISOString();
      const fullName = `${first_name || ''} ${middle_name || ''} ${last_name || ''}`.trim();

      return {
        id,
        title: `${fullName} - Appointment with ${appointment_with}`,
        start: appointment_date,
        end: newTimeString,
        first_name,
        middle_name,
        last_name,
        service_name: service,
        appointment_with,
        reason_for_visit: visit_reason,
        dob,
        gender,
        contact,
        clinic,
        duration,
        patient_id,
        current_status: appointment.current_status,
      };
    }) || [];
  }, [data]);

  const disabledDate = useCallback((current) => current && current > new Date(), []);

  const handleProviderSearch = useCallback((value) => {
    if (!providers) return;
    
    const filteredSuggestions = providers.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  }, [providers]);

  const handleSelect = useCallback((value, option) => {
    setSelectedDProvider({
      id: option.key,
      name: option.children,
    });
  }, []);

  const notRegistered = useCallback((e) => {
    setIsNotRegistered(e.target.checked);
    
    if (e.target.checked) {
      // If unregistered is checked, clear the patient name field
      form.setFields([
        {
          name: 'patient_name',
          value: undefined
        }
      ]);
      // Reset client details
      setClientDetails(null);
      setPatientName(null);
    }
  }, [form]);

  const handleDateClick = useCallback((selected) => {
    if (!selected) return;
    
    const date = selected.date;
    const timeObj = createTimeObject(date, officeTimezone);
    const selectedTime = DateTime.fromJSDate(date, { zone: officeTimezone });
    const formattedDate = selectedTime.toFormat("MM-dd-yyyy");
    
    setAppointmentDate(formattedDate);
    setAppointmentTime(timeObj.displayTime);
    setVisible(true);
    
    form.setFieldsValue({
      start_time: timeObj.dayjsTime,
      appointment_date: dayjs(formattedDate),
    });
  }, [form, officeTimezone]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    const relevantFields = ['appointment_date', 'start_time', 'duration', 'appointment_with'];
    const hasRelevantChanges = relevantFields.some(field => field in changedValues);
    
    if (!hasRelevantChanges) return;
    
    const selectedDate = allValues.appointment_date;
    const provider = allValues.appointment_with;
    const selectedStartTime = allValues.start_time || dayjs().startOf("day");
    const selectedDuration = allValues.duration || 15;

    if (selectedDate) {
      const formattedDate = dayjs(selectedDate).format("MM-DD-YYYY");
      
      // Ensure we're using the 24-hour values from the dayjs object
      // This prevents 12 PM from being incorrectly treated as 12 AM
      const hour24 = selectedStartTime.hour();
      const minute = selectedStartTime.minute();
      
      // Format times for display
      const formattedStartTime = selectedStartTime.format("h:mm a");
      const formattedEndTime = selectedStartTime
        .add(selectedDuration, "minutes")
        .format("h:mm a");

      setStartTime(formattedStartTime);
      setAppointmentDate(formattedDate);
      setAppointmentWith(provider);
      setAppointmentTime(`${formattedStartTime} - ${formattedEndTime}`);
      
      // Log for debugging
      console.log(`Time selected: ${hour24}:${minute} (${formattedStartTime})`);
    }
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
    setSelectedAppointment(null);
    setAppointmentDate(null);
    setAppointmentWith(null);
    setClientDetails(null);
    setIsNotRegistered(false);
    setPatientName(null);
    
    // Use a small timeout to prevent visual glitches
    setTimeout(() => {
      setEdit(false);
      form.resetFields();
    }, 200);
  }, [form]);

  const deleteAppointment = useCallback(async (record) => {
    try {
      const response = await doDelete(`/appointments/delete/${record}/`);
      if (response.status === 200) {
        Notification.success("Appointment deleted successfully");
        getAppointments();
        handleCancel();
      }
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  }, [getAppointments, handleCancel]);

  const handleDelete = useCallback((selectedAppointment) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        deleteAppointment(selectedAppointment);
      },
    });
  }, [deleteAppointment]);

  const handleEventClick = useCallback((selected) => {
    if (!selected?.event) return;
    
    setEdit(true);
    setSelectedAppointment(selected.event._def.publicId);

    const { start, end } = selected.event;
    const {
      service_name,
      appointment_with,
      reason_for_visit,
      first_name,
      middle_name,
      last_name,
      patient_id,
      contact,
      dob,
    } = selected.event.extendedProps;

    // Get the date and time with the helper function
    const formattedDate = moment(start).tz(officeTimezone);
    const timeObj = createTimeObject(formattedDate, officeTimezone);
    
    const patient_name = first_name && last_name ? `${first_name} ${middle_name || ''} ${last_name}`.trim() : "";

    setAppointmentDate(formattedDate);
    setAppointmentTime(timeObj.displayTime);
    setAppointmentWith(appointment_with);
    setPatientName(patient_name);

    const commonFields = {
      appointment_date: dayjs(formattedDate),
      start_time: timeObj.dayjsTime,
      duration: moment(end).diff(moment(start), "minutes"),
      service: service_name,
      appointment_with: appointment_with,
      reason_for_visit: reason_for_visit || "",
    };

    if (!patient_id) {
      form.setFieldsValue({
        unregistered_patient: true,
        first_name,
        last_name,
        middle_name,
        phone: contact,
        dob: dob ? dayjs(dob) : null,
        ...commonFields,
      });
      setIsNotRegistered(true);
      // Clear the patient_name field to avoid confusion
      form.setFields([
        {
          name: 'patient_name',
          value: undefined
        }
      ]);
    } else {
      form.setFieldsValue({
        patient_name,
        ...commonFields,
      });
      setIsNotRegistered(false);
    }
    
    setVisible(true);
  }, [form, officeTimezone]);

  const onSelect = useCallback((value, option) => {
    const selectedClient = nameOptions.find((item) => item.value === value);
    if (!selectedClient) return;

    // If a patient is selected, set isNotRegistered to false
    setIsNotRegistered(false);
    
    // Update form to uncheck the unregistered_patient checkbox
    form.setFieldsValue({
      unregistered_patient: false
    });

    setClientDetails({
      patient_id: option?.id,
      first_name: option?.first_name,
      middle_name: option?.middle_name,
      last_name: option?.last_name,
      patient_MRN: option?.mrn_number,
      dob: option?.date_of_birth,
      contact: option?.patient_phone,
      gender: option?.gender,
      profile_picture: option?.profile_picture,
    });
  }, [nameOptions, form]);

  const handleServiceSelect = (value) => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      form.setFieldsValue({service: selectedOption?.service_name})
    }
  };

  const handleAppoinmentSchedule = useCallback(async (values) => {
    setSubmit(true);
    
    try {
      const {
        first_name,
        middle_name,
        last_name,
        phone,
        start_time,
        duration,
        service,
        reason_for_visit,
        dob,
      } = values;
      
      const apt_date = values?.appointment_date?.format("YYYY-MM-DD");
      
      // Ensure we're using the 24-hour format for the time to avoid AM/PM confusion
      // This is especially important for 12 PM vs 12 AM
      const hour24 = start_time.hour();
      const minute = start_time.minute();
      const timeString = `${hour24.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      
      // Create the appointment datetime in the correct timezone
      const appointment_dateTime = moment
        .tz(`${apt_date} ${timeString}`, officeTimezone)
        .utc()
        .format();
      
      // Log for debugging
      console.log(`Appointment time: ${timeString} (${start_time.format("h:mm a")})`);
      
      const data = {
        patient_id: clientDetails?.patient_id,
        first_name: clientDetails?.first_name || first_name,
        middle_name: clientDetails?.middle_name || middle_name,
        last_name: clientDetails?.last_name || last_name,
        patient_MRN: clientDetails?.patient_MRN,
        gender: clientDetails?.gender,
        dob: clientDetails?.dob || dob,
        contact: clientDetails?.contact || phone,
        appointment_date: appointment_dateTime,
        time_zone_value: officeTimezone,
        time: `${hour24.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`, // Use 24-hour format
        duration: duration || 15,
        service: service,
        appointment_with: selectedProvider?.name,
        provider_id: selectedProvider?.id,
        visit_reason: reason_for_visit,
        current_status: edit ? "Rescheduled" : "Scheduled",
      };
      
      const response = edit
        ? await doPatch(`/appointments/update/${selectedAppointment}/`, data)
        : await doPost("/appointments/make/", data);
      
      if (response.status === 201 || response.status === 200) {
        Notification.success(edit ? SUCCESS_MESSAGES.update : SUCCESS_MESSAGES.add);
        getAppointments();
        handleCancel();
      } else if (response.status === 406) {
        Notification.warning("Provider not availble in this duration");
      } else if (response.status === 400) {
        Notification.error("Appointment Date can not be in the past");
      }
    } catch (error) {
      console.error("Error scheduling appointment:", error);
      Notification.error("Something Went Wrong");
    } finally {
      setSubmit(false);
    }
  }, [clientDetails, edit, getAppointments, handleCancel, officeTimezone, selectedAppointment, selectedProvider]);

  const handleEventDrop = useCallback(async (eventDropInfo) => {
    // Get the event that was dropped
    const { event } = eventDropInfo;
    const appointmentId = event._def.publicId;
    
    // Get the new start and end times
    const newStart = event.start;
    const newEnd = event.end;
    
    // Format the new date and time for the API
    const newDateTime = moment(newStart).tz(officeTimezone);
    const hour24 = newDateTime.hour();
    const minute = newDateTime.minute();
    const timeString = `${hour24.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;
    
    // Calculate duration in minutes
    const durationMinutes = newEnd ? moment(newEnd).diff(moment(newStart), 'minutes') : 15;
    
    try {
      // Show confirmation dialog
      showConfirmModal({
        title: "Confirm Reschedule",
        content: `Are you sure you want to reschedule this appointment to ${newDateTime.format('MMM D, YYYY')} at ${newDateTime.format('h:mm A')}?`,
        okText: "Reschedule",
        cancelText: "Cancel",
        onOk: async () => {
          try {
            // Prepare data for the API
            const data = {
              appointment_date: newDateTime.utc().format(),
              time_zone_value: officeTimezone,
              time: timeString,
              duration: durationMinutes,
              current_status: "Rescheduled"
            };
            
            // Call the API to update the appointment
            const response = await doPatch(`/appointments/update/${appointmentId}/`, data);
            
            if (response.status === 200) {
              Notification.success("Appointment rescheduled successfully");
              getAppointments();
            } else {
              Notification.error("Failed to reschedule appointment");
              getAppointments(); // Refresh to revert the UI
            }
          } catch (error) {
            console.error("Error rescheduling appointment:", error);
            Notification.error("An error occurred while rescheduling");
            getAppointments(); // Refresh to revert the UI
          }
        },
        onCancel: () => {
          // Revert the change in the UI
          eventDropInfo.revert();
        }
      });
    } catch (error) {
      console.error("Error in handleEventDrop:", error);
      eventDropInfo.revert();
    }
  }, [officeTimezone, getAppointments]);

  const handleEventResize = useCallback(async (eventResizeInfo) => {
    // Get the event that was resized
    const { event } = eventResizeInfo;
    const appointmentId = event._def.publicId;
    
    // Get the new start and end times
    const newStart = event.start;
    const newEnd = event.end;
    
    // Calculate new duration in minutes
    const durationMinutes = moment(newEnd).diff(moment(newStart), 'minutes');
    
    try {
      // Show confirmation dialog
      showConfirmModal({
        title: "Confirm Duration Change",
        content: `Are you sure you want to change this appointment duration to ${durationMinutes} minutes?`,
        okText: "Update",
        cancelText: "Cancel",
        onOk: async () => {
          try {
            // Prepare data for the API
            const data = {
              duration: durationMinutes,
              current_status: "Rescheduled"
            };
            
            // Call the API to update the appointment
            const response = await doPatch(`/appointments/update/${appointmentId}/`, data);
            
            if (response.status === 200) {
              Notification.success("Appointment duration updated successfully");
              getAppointments();
            } else {
              Notification.error("Failed to update appointment duration");
              getAppointments(); // Refresh to revert the UI
            }
          } catch (error) {
            console.error("Error updating appointment duration:", error);
            Notification.error("An error occurred while updating duration");
            getAppointments(); // Refresh to revert the UI
          }
        },
        onCancel: () => {
          // Revert the change in the UI
          eventResizeInfo.revert();
        }
      });
    } catch (error) {
      console.error("Error in handleEventResize:", error);
      eventResizeInfo.revert();
    }
  }, [getAppointments]);


  // Fetch appointments when view changes
  useEffect(() => {
    if (currentView?.startStr && currentView?.endStr) {
      getAppointments();
    }
  }, [currentView?.startStr, currentView?.endStr, getAppointments]);

  // Render avatar with fallback
  const renderAvatar = () => {
    const hasProfilePicture = clientDetails?.profile_picture;
    const firstInitial = clientDetails?.first_name?.[0] || 'A';
    
    return (
      <Avatar
        size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
        src={hasProfilePicture ? clientDetails.profile_picture : null}
      >
        {!hasProfilePicture && firstInitial.toUpperCase()}
      </Avatar>
    );
  };

  // Render patient info section
  const renderPatientInfo = () => {
    const displayName = clientDetails?.first_name
      ? patient_name
      : edit
      ? patientName
      : "Search Patient";
      
    return (
      <div className="left">
        {renderAvatar()}
        <p style={{ fontSize:'16px' }} className="text-white font-semibold">
          {displayName}
        </p>
        <hr width="80%" />
        <p className="text-white" style={{fontSize:'16px'}}>Selected Date and Time</p>
        <p className="font-semibold" style={{fontSize:'16px'}}>
          <FormatDate date={appointmentDate} />
        </p>
        <p className="font-semibold" style={{fontSize:'16px'}}>{appointmentTime || "Appointment Time"}</p>
        <hr width="80%" />
        <p className="text-white" style={{fontSize:'16px'}}>Appointment With</p>
        <p className="text-white" style={{fontSize:'16px'}}> {appointmentWith || "Provider Name"}</p>
      </div>
    );
  };

  // Render unregistered patient fields
  const renderUnregisteredFields = () => {
    if (!isNotRegistered) return null;
    
    return (
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="first_name" label="First Name">
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="last_name" label="Last Name">
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="dob" label="DOB">
              <BaseDatePicker disabledDate={disabledDate} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="phone" 
              label="Phone"
              rules={[{ validator: phoneValidator }]}
            >
              <Input placeholder="Phone" maxLength={20} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  // Enhanced event render function with better tooltip
  const renderEventContent = useCallback((eventInfo) => {
    const { event } = eventInfo;
    const {
      first_name,
      middle_name,
      last_name,
      current_status
    } = event.extendedProps;

    const patientName = `${first_name || ''} ${middle_name || ''} ${last_name || ''}`.trim();
    const startTime = moment(event.start).tz(officeTimezone).format('h:mm a');
    
    // Check if we're in list view
    const isListView = () => {
      if (typeof currentView === 'object' && currentView.view) {
        return currentView.view.type === 'listMonth';
      } else if (calendarRef.current?.getApi()) {
        return calendarRef.current.getApi().view.type === 'listMonth';
      }
      return false;
    };

    // Check if we're in month view
    const isMonthView = () => {
      if (typeof currentView === 'object' && currentView.view) {
        return currentView.view.type === 'dayGridMonth';
      } else if (calendarRef.current?.getApi()) {
        return calendarRef.current.getApi().view.type === 'dayGridMonth';
      }
      return false;
    };
    
    // Determine color based on status
    const statusColors = {
      scheduled: "bg-blue-200",
      confirmed: "bg-green-200",
      rescheduled: "bg-blue-200",
      late_arrival: "bg-yellow-200",
      no_show: "bg-red-200",
      cancelled: "bg-red-200",
    };
    
    const borderColor = statusColors[current_status] || 'bg-green-700';

    // In month view, just return the basic content without Popover to improve performance
    if (isMonthView()) {
      return (
        <div className="fc-event-main-wrapper" style={{ borderLeft: `3px solid ${borderColor}` }}>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
              {`${patientName} - ${startTime}`}
            </div>
          </div>
        </div>
      );
    }

    // Generate the tooltip content function - only gets called when hovering
    const generateTooltipContent = () => {
      const {
        service_name,
        appointment_with,
        reason_for_visit,
        contact,
        dob,
        gender,
      } = event.extendedProps;
      
      const endTime = moment(event.end).tz(officeTimezone).format('h:mm a');
      const appointmentDate = moment(event.start).tz(officeTimezone).format('MMM DD, YYYY');
      
      return (
        <div className="appointment-tooltip">
          <div style={{ marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <strong style={{ fontSize: '14px' }}>{patientName}</strong>
              <AppointmentStatus status={current_status}/>
            </div>
            {dob && (
              <div style={{ fontSize: '12px', color: '#666', marginTop: '2px' }}>
                DOB: {moment(dob).format('MM/DD/YYYY')} • {gender || 'Unknown'}
              </div>
            )}
          </div>
          
          <div style={{ display: 'flex', marginBottom: '8px' }}>
            <div style={{ width: '24px', marginRight: '8px', color: '#1890ff' }}>
              <CalendarOutlined />
            </div>
            <div>
              <div>{appointmentDate}</div>
            </div>
          </div>
          
          <div style={{ display: 'flex', marginBottom: '8px' }}>
            <div style={{ width: '24px', marginRight: '8px', color: '#1890ff' }}>
              <ClockCircleOutlined />
            </div>
            <div>
              <div>{startTime} - {endTime}</div>
            </div>
          </div>
          
          <div style={{ display: 'flex', marginBottom: '8px' }}>
            <div style={{ width: '24px', marginRight: '8px', color: '#1890ff' }}>
              <UserOutlined />
            </div>
            <div>
              <div><strong>Provider:</strong> {appointment_with}</div>
              <div><strong>Service:</strong> {service_name}</div>
            </div>
          </div>
          
          {contact && (
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <div style={{ width: '24px', marginRight: '8px', color: '#1890ff' }}>
                <PhoneOutlined />
              </div>
              <div>{contact}</div>
            </div>
          )}
          
          {reason_for_visit && (
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <div style={{ width: '24px', marginRight: '8px', color: '#1890ff' }}>
                <FileTextOutlined />
              </div>
              <div>
                <div><strong>Reason:</strong></div>
                <div style={{ fontSize: '12px' }}>{reason_for_visit}</div>
              </div>
            </div>
          )}
        </div>
      );
    };

    // Determine the best placement based on the view
    const getPopoverPlacement = () => {
      // Get current calendar view
      let calendarViewType;
      
      if (typeof currentView === 'object' && currentView.view) {
        calendarViewType = currentView.view.type;
      } else if (calendarRef.current?.getApi()) {
        calendarViewType = calendarRef.current.getApi().view.type;
      } else {
        calendarViewType = typeof currentView === 'string' ? currentView : 'dayGridMonth';
      }
      
      // For list view, center the popover
      if (calendarViewType === 'listMonth') {
        return 'top';
      }

      if (calendarViewType === 'timeGridWeek') {
        return 'right';
      }
      
      // For day views, always place popover on top to prevent hiding appointments
      if (calendarViewType === 'timeGridDay') {
        return 'top';
      }
      
      return 'rightTop';
    };

    // For list view
    if (isListView()) {
      return (
        <Popover 
          content={generateTooltipContent}
          title={`Appointment Details`}
          trigger="hover"
          overlayClassName="appointment-popover list-view-popover"
          placement="top"
          mouseEnterDelay={0.3}
          mouseLeaveDelay={0.2}
          overlayStyle={{ maxWidth: '350px' }}
          getPopupContainer={() => document.body}
          arrow={true}
          destroyTooltipOnHide={true}
          zIndex={1100}
        >
          <div className="fc-list-event-title">
            {event.title}
          </div>
        </Popover>
      );
    }

    // For other views (timeGridDay, timeGridWeek)
    return (
      <Popover 
        content={generateTooltipContent}
        title={`Appointment Details`}
        trigger="hover"
        overlayClassName="appointment-popover"
        placement={getPopoverPlacement()}
        mouseEnterDelay={0.3}
        mouseLeaveDelay={0.2}
        overlayStyle={{ maxWidth: '350px' }}
        getPopupContainer={() => document.body}
        arrow={true}
        destroyTooltipOnHide={true}
        zIndex={1100}
      >
        <div className="fc-event-main-wrapper" style={{ borderLeft: `3px solid ${borderColor}` }}>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
              {event.title}
            </div>
          </div>
        </div>
      </Popover>
    );
  }, [officeTimezone, currentView]);

  // Handle event mounting to ensure popovers work in all views
  const handleEventDidMount = useCallback((info) => {
    const { el, view, event } = info;
    
    // Add specific handling for list view
    if (view.type === 'listMonth') {
      // Find the title element in the list view
      const titleEl = el.querySelector('.fc-list-event-title');
      if (titleEl) {
        // Make sure the title is clickable
        titleEl.style.cursor = 'pointer';
        
        // Add a class to help with styling
        el.classList.add('fc-list-event-with-popover');
      }
    }
    
    // For month view, optimize rendering and add click event
    if (view.type === 'dayGridMonth') {
      // Add cursor pointer to indicate clickability
      requestAnimationFrame(() => {
        el.style.cursor = 'pointer';
        
        // Set a data attribute to identify the event by ID
        el.setAttribute('data-event-id', event.id);
        
        // Reduce DOM operations by minimizing style changes
        // Add minimal styles directly
        el.style.overflow = 'hidden';
        el.style.textOverflow = 'ellipsis';
        el.style.whiteSpace = 'nowrap';
        
        // Use simple class instead of complex style manipulations
        el.classList.add('month-view-event');
      });
    }
  }, []);

  // Additional function to handle month view event click and show details
  const handleMonthViewEventClick = useCallback((eventClickInfo) => {
    // If we're in month view, show a modal with details instead of editing
    if (currentView?.view?.type === 'dayGridMonth') {
      // Instead of showing popover, trigger the event click handler directly
      // This will open the appointment modal with all details
      handleEventClick(eventClickInfo);
    } else {
      // For other views, use the default event click handler
      handleEventClick(eventClickInfo);
    }
  }, [currentView, handleEventClick]);

  return (
    <div className="calender mb-5">
      <TimeClock />
      <div className="flex justify-center mt-4"></div>
      <FullCalendar
        ref={calendarRef}
        height="70vh"
        timeZone={officeTimezone || "America/Central"}
        datesSet={(info) => setCurrentView(info)}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
          luxonPlugin,
        ]}
        nowIndicator={true}
        headerToolbar={{
          left: "prev,today,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        }}
        slotDuration="00:15:00"
        slotMinTime={calendarOpeningTime}
        slotMaxTime={calendarClosingTime}
        initialView="timeGridDay"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={3} // Limit visible events in month view 
        moreLinkClick="popover" // Show more link as popover instead of new view
        dayMaxEventRows={3} // Limit event rows in month view
        lazyFetching={true} // Optimize for lazy loading events
        progressiveEventRendering={true} // Render events progressively for better UI responsiveness
        dateClick={handleDateClick}
        eventClick={handleMonthViewEventClick}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
        eventDidMount={handleEventDidMount}
        events={events}
        eventContent={renderEventContent}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short'
        }}
        forceEventDuration={true}
      />
      
      <Modal
        open={isVisible}
        closeIcon={
          <Button size="small" className="app-close-icon" shape="round">
            Close
          </Button>
        }
        onCancel={handleCancel}
        footer={null}
        width={680}
        maskClosable={false}
        className="appointment_modal"
      >
        <div className="appointment_box">
          {renderPatientInfo()}
          
          <Form
            layout="vertical"
            form={form}
            onValuesChange={handleFormValuesChange}
            onFinish={handleAppoinmentSchedule}
            className="appointment-form"
          >
            <div className="right-header">
              <p className="mb-4">
                {edit ? "Update Appointment" : "Book a New Appointment"}
              </p>
            </div>
            
            <Form.Item
              name="patient_name"
              label="Search Patient"
              tooltip="If No Patient Found Please Select New Unregistered Patient"
            >
              <AutoComplete
                showSearch
                options={nameOptions}
                allowClear={true}
                onSelect={onSelect}
                onSearch={handleSearchPatient}
                placeholder="Search for existing Patient"
                filterOption={false}
                notFoundContent={!nameOptions.length ? "No matches found" : null}
              />
            </Form.Item>
            
            <Form.Item name="unregistered_patient" valuePropName="checked">
              <Checkbox onChange={notRegistered}>
                New Unregistered Patient or Client
                <Tooltip title="Check the box if the Patient or Client is not registered">
                  <QuestionCircleOutlined className="tooltip__icon" />
                </Tooltip>
              </Checkbox>
            </Form.Item>
            
            {renderUnregisteredFields()}
            
            <div className="right-header">
              <p className="mb-4">Appointment Details</p>
            </div>
            
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="appointment_date"
                  label="Date"
                  rules={[{ required: true, message: "Please Select Appointment Date" }]}
                >
                  <AppointmentDatePicker value={appointmentDate} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="start_time"
                  label="Start Time"
                  rules={[{ required: true, message: "Start Time is Required" }]}
                >
                  <TimePicker use12Hours format={TIME_FORMAT} minuteStep={5} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="duration" label="Duration" initialValue="15">
                  <Select placeholder="Duration">
                    {DURATION_OPTIONS.map(option => (
                      <Option key={option.value} value={option.value}>{option.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            
            <Form.Item
              name="service"
              label="Service Name"
              rules={[{ required: true, message: "Service is Required" }]}
            >
              <AutoComplete
                options={options}
                allowClear={true}
                onSelect={handleServiceSelect}
                width={"100%"}
                onSearch={handleSearchServices}
                placeholder="Search for service name"
              />
            </Form.Item>
            
            <Row>
              <Col span={12}>
                <Form.Item
                  name="appointment_with"
                  label="Provider Name"
                  rules={[{ required: true, message: "Provider is Required" }]}
                >
                  <Select
                    showSearch
                    placeholder="Search for providers"
                    style={{width:'394px'}}
                    onSearch={handleProviderSearch}
                    onSelect={handleSelect}
                    optionFilterProp="children"
                    filterOption={false}
                  >
                    {suggestions?.map((doctor) => (
                      <Option key={doctor.id} value={doctor.name}>
                        {doctor.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            
            <Form.Item
              name="reason_for_visit"
              label="Reason"
              rules={[{ required: true, message: "Reason For Visit is Required" }]}
            >
              <Input.TextArea
                placeholder="Reason for visit"
                maxLength={300}
                showCount
              />
            </Form.Item>
            
            <div className="flex justify-between mt-[1.4rem]">
              <Button onClick={handleCancel}>Cancel</Button>

              <div>
                {edit && (
                  <Button
                    className="danger__button mr-2"
                    onClick={() => handleDelete(selectedAppointment)}
                  >
                    Delete
                  </Button>
                )}
                <Button type="primary" htmlType="submit" loading={submit}>
                  {edit ? "Update" : "Book Appointment"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AppointmentCalendar;
