import React, { useMemo } from "react";
import Notification from "../Notification/Notification";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Image,
  Radio,
  Space,
  Tooltip,
} from "antd";
import {
  BsBank,
  BsCardChecklist,
  BsCashCoin,
  BsFillCreditCard2BackFill,
} from "react-icons/bs";
import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  ReloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { useStateGlobalContext } from "../../contexts/GlobalContext";
import { Status } from "../../utils/Data";

const radioOptions = [
  { label: "CARD/POS", icon: <BsFillCreditCard2BackFill />, value: "card" },
  { label: "CASH", icon: <BsCashCoin />, value: "cash" },
  { label: "BANK WIRE", icon: <BsBank />, value: "bank" },
  { label: "CHECK", icon: <BsCardChecklist />, value: "check" },
];

export const maxSize = 2 * 1024 * 1024; 

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      Notification.success("Copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export const CopyableText = ({ text, className = "" }) => {
  return (
    <Tooltip title="Click to copy">
      <span
        onClick={() => copyToClipboard(text)}
        className={`${className} cursor-pointer`}
      >
        {text}
      </span>
    </Tooltip>
  );
};

export const RadioGroup = ({ value, onChange, ...rest }) => {
  return (
    <Radio.Group
      value={value}
      onChange={onChange}
      {...rest}
      className="radio-tile-group"
      buttonStyle="solid"
    >
      {radioOptions.map((option, index) => (
        <Radio.Button key={index} value={option.value}>
          <span className="flex flex-row justify-center items-center gap-2">
            {option.icon && <span>{option.icon}</span>}
            <p>{option.label}</p>
          </span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export const SearchProduct = ({ options, onSelect, onSearch }) => {
  return (
    <AutoComplete
      options={options}
      allowClear={true}
      onSelect={onSelect}
      onSearch={onSearch}
      placeholder="Search Products"
    />
  );
};

export const RaceRadioGroup = ({ defaultValue = "notAsked", onChange }) => {
  return (
    <Radio.Group
      className="mb-4"
      defaultValue={defaultValue}
      onChange={onChange}
    >
      <Space direction="vertical">
        <Radio value="americanIndian">American Indian or Alaska Native</Radio>
        <Radio value="asian">Asian</Radio>
        <Radio value="black">Black or African American</Radio>
        <Radio value="nativeHawaiian">
          Native Hawaiian or Other Pacific Islander
        </Radio>
        <Radio value="white">White</Radio>
        <Radio value="other">Other</Radio>
        <Radio value="decline">Decline to specify</Radio>
        <Radio value="notAsked">Provider didn't ask</Radio>
      </Space>
    </Radio.Group>
  );
};

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ["bold", "italic"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};

export const ImageView = ({ src }) => {
  return (
    <Image
      className="image-preview"
      src={src}
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: {
              onFlipY,
              onFlipX,
              onRotateLeft,
              onRotateRight,
              onZoomOut,
              onZoomIn,
            },
          }
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <SwapOutlined rotate={90} onClick={onFlipY} />
            <SwapOutlined onClick={onFlipX} />
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    />
  );
};

export const fullName = (name) => {
  return (
    <span>
      {" "}
      {name?.first_name} {name?.middle_name && name?.middle_name}{" "}
      {name?.last_name}
    </span>
  );
};

export const fullAddress = (address) => {
  if (!address) return null;

  const parts = [
    address?.current_address_line_1,
    address?.current_address_line_2,
    address?.current_city,
    address?.current_state,
    address?.current_country,
    address?.current_zipcode,
  ].filter(Boolean); // Remove undefined, null, or empty values

  return <span>{parts.join(", ")}</span>;
};

export const InvoiceBar = ({ invoice, title = "Invoice ID" }) => {
  const { officeSetting } = useStateGlobalContext();
  return (
    <div className="py-2 text-slate-600 bg-slate-200 px-2 font-semibold">
      <div className="flex items-center justify-between">
        <Avatar
          src={officeSetting?.user_office_logo}
          alt="EMR"
          className="w-12 h-12"
        />
        <div>
          {title}:{" "}
          {invoice?.invoice_id || invoice?.inhouseUID || invoice?.lab_id}{" "}
          <Status status={invoice?.status} />
        </div>
      </div>
    </div>
  );
};

export const DetailBox = ({ className, children }) => (
  <div className={`mt-3 bg-blue-100 p-3 rounded-xl ${className}`}>
    {children}
  </div>
);

export const DeleteButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      danger
      icon={<DeleteOutlined />}
      className="flex items-center px-4 py-2 danger__button  text-white shadow-md"
    >
      Remove
    </Button>
  );
};

export const RefreshButton = ({ onClick }) => {
  return (
    <Button
      type="default"
      onClick={onClick}
      icon={<ReloadOutlined />}
      className="flex items-center  px-4 py-2 shadow-md"
    >
      Refresh
    </Button>
  );
};

export const DownloadButton = ({ children }) => {
  return (
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      className="flex items-center  px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white shadow-lg"
    >
      {children}
    </Button>
  );
};

export const CloseButton= (onClose) => (
  <Button size="small" className="drawer-close-icon" shape="round" onClick={onClose}>
    Close
  </Button>
);

export const AddButton= ({openModal}) => (
  <Button type="primary" size="small" shape="round" onClick={openModal}>
  <PlusOutlined />Add 
  </Button>
);

export const DetailCard = ({title = "Card", extra, children})=>(
  <Card title={title} extra={extra}>
      {children}
  </Card>
)