import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Loader from "../../../../components/Loader/Loader";

const TotalPatientCharts = ({ data, loading }) => {
  const [chartSeries, setChartSeries] = useState([]);
  const [maxYAxis, setMaxYAxis] = useState(0);
  const [chartCategories, setChartCategories] = useState([]);

  useEffect(() => {
    if (data) {
      const seriesData = Object.values(data);
      const maxValue = Math.max(...seriesData) + 20;

      setMaxYAxis(maxValue);
      setChartSeries([{ 
        name: "Patient Registered",
        data: seriesData 
      }]);

      const categories = Object.keys(data).map((date) => {
        // Extract year and month
        const [year, month] = date.split("-");
        return `${month.substring(0, 3)} ${year}`; 
      });
      setChartCategories(categories);
    }
  }, [data]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: true,
      },
      fontFamily: 'inherit',
    },
    title: {
      text: 'Monthly Patient Registration',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'inherit',
        color: '#333'
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 4,
        distributed: false,
      },
    },
    colors: ['#3384ef'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartCategories,
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'inherit',
        },
      },
      axisBorder: {
        show: true,
        color: '#e0e0e0',
      },
      axisTicks: {
        show: true,
        color: '#e0e0e0',
      },
    },
    yaxis: {
      title: {
        text: "Patients",
        style: {
          fontSize: '14px',
          fontFamily: 'inherit',
        },
      },
      max: maxYAxis,
      labels: {
        formatter: function(val) {
          return Math.round(val);
        },
        style: {
          fontSize: '12px',
          fontFamily: 'inherit',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: '#f1f1f1',
      row: {
        colors: ['transparent', 'transparent'],
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " patients";
        },
      },
      theme: "light",
      style: {
        fontSize: '12px',
        fontFamily: 'inherit'
      },
      marker: {
        show: true,
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            bar: {
              columnWidth: '70%',
            },
          },
        },
      },
    ],
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ReactApexChart options={options} series={chartSeries} type="bar" height={320} />
  );
};

export default TotalPatientCharts;