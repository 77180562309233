import { AutoComplete } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSearch } from "../../API/apiUtils";

function Searchbar({ search_width, navigate_to, role }) {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(""); // Manage the value of AutoComplete

  const onSelect = (value, option) => {
    if (option && option.key) {
      const basePath = `/main/charts/patient/${option.key}`;
      const targetPath = role === "biller" ? `${basePath}/visits` : basePath;
      navigate_to && navigate(targetPath); // Dynamically navigate based on the role
    }
    setSearchValue(""); 
  };

  const { nameOptions: options, handleSearch } = useSearch();

  const handleInputChange = (value) => {
    setSearchValue(value); 
    handleSearch(value);  
  };

  // Determine width based on the prop or default to responsive width
  const width = search_width || "100%";

  return (
    <div className="w-full md:w-auto">
      <AutoComplete
        value={searchValue}  
        options={options}  
        allowClear={true}
        onSelect={onSelect}  
        onSearch={handleInputChange}  
        placeholder="Search Patient"
        style={{ width }}
        className="responsive-search"
      />
    </div>
  );
}

export default Searchbar;
