import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Row,
  Col,
  Select,
  AutoComplete,
  message,
} from "antd";
import BaseDatePicker from "../../../../components/BaseDatePicker/BaseDatePicker";
import NotesHeader from "../GeneralNotes/NotesHeader";
import {
  useSearchDiagnosis,
  useSearchReferralDoctor,
} from "../../../../API/apiUtils";
import { doGet, doPost } from "../../../../API/apis";
import { useParams } from "react-router-dom";
import { useStateGlobalContext } from "../../../../contexts/GlobalContext";
import Notification from "../../../../components/Notification/Notification";
import LabelDisplay from "../../../../components/LabelDisplay/LabelDisplay";
import Loader from "../../../../components/Loader/Loader";

const { Option } = Select;

const ReferalDrawer = ({ visible, onClose }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [providerInfo, setProviderInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [referralToDetails, setReferralToDetails] = useState([]);
  const [insuranceLoading, setInsuranceLoading] = useState(false);
  const { getReferral } = useStateGlobalContext();
  const [insuranceInfo, setInsuranceInfo] = useState([]);
  const disabledDate = (current) => {
    const today = new Date();
    // Compare only the date part without time
    return current && current < new Date(today.setHours(0, 0, 0, 0));
  };

  const handleCancel = () => {
    onClose();
    setInsuranceInfo(null);
    setTimeout(() => {
      form.resetFields();
    }, 300);
  };



  const { diagnosisOptions: options, handleSearchDiagnosis } =
    useSearchDiagnosis();
  const { referralOptions, handleSearchReferralDoctor } =
    useSearchReferralDoctor();

  const getInsuranceInfo = async (insurance_type) => {
    setInsuranceLoading(true);
    try {
      const response = await doGet(
        `/insurance-claims/insurance-info/${id}/${insurance_type}/`
      );
      if (response.status === 200) {
        setInsuranceInfo({
          insurance_type: response?.data[0]?.insurance_type,
          insurance_company:
            response?.data[0]?.insurance_company?.insurance_company_name,
          insurance_plan:
            response?.data[0]?.insured_person_info?.insurance_plan,
          insurance_group:
            response?.data[0]?.insured_person_info?.insured_group,
          insurance_policy:
            response?.data[0]?.insured_person_info?.insurance_policy,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInsuranceLoading(false);
    }
  };


  const getProviderInfo = async () => {
    try {
      const response = await doGet("/referral/settings-get/");
      if (response.status === 200) {
        setProviderInfo(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSelect = (value, option) => {
    const selectedReferralDoctor = referralOptions.find(
      (item) => item.value === value
    );
    if (selectedReferralDoctor) {
      setReferralToDetails({
        referral_to_id: option?.id,
        referral_to_fullname: option?.full_name,
        referral_to_facility_name: option?.facility_name,
        referral_to_phone: option?.phone,
        referral_to_email: option?.email,
        referral_to_fax: option?.fax,
        referral_to_full_address: option?.full_address,
        referral_to_speciality: option?.speciality,
        referral_to_operating_hours: option?.operating_hrs,
        referring_provider_notes: option?.referring_provider_notes
      });
    }
  };

  const handleReferral = async (values) => {
    if (!referralToDetails?.referral_to_fullname) {
      return message.error("Please select a provider from the options.");
    }
  
    setLoading(true);
    const data = {
      referral_from_name: providerInfo?.user || "",
      referral_from_speciality:
        providerInfo?.medical_identifier[0]?.primary_speciality[0] || "",
      referral_from_phone:
        providerInfo?.personel_settings[0]?.contact?.work_phone || "",
      referral_from_fax:
        providerInfo?.personel_settings[0]?.contact?.fax_number || "",
      referral_from_email: providerInfo?.email,
      start_date: values?.start_date,
      end_date: values?.end_date,
      auth_code: values?.authorization_code,
      insurance_company: insuranceInfo?.insurance_company,
      insurance_group: insuranceInfo?.insurance_group,
      insurance_plan: insuranceInfo?.insurance_plan,
      insurance_type: insuranceInfo?.insurance_type,
      insurance_policy: insuranceInfo?.insurance_policy,
      referral_to_full_name: referralToDetails?.referral_to_fullname,
      referral_to_facility_name: referralToDetails?.referral_to_facility_name,
      referral_to_speciality: referralToDetails?.referral_to_speciality,
      referral_to_address: referralToDetails?.referral_to_full_address,
      referral_to_email: referralToDetails?.referral_to_email,
      referral_to_phone: referralToDetails?.referral_to_phone,
      referral_to_fax: referralToDetails?.referral_to_fax,
      referral_to_operating_hours:
        referralToDetails?.referral_to_operating_hours,
      referral_to_notes : referralToDetails?.referring_provider_notes,
      instructions: values?.referral_instructions,
      reason_for_referral: values?.reason_for_referral,
      patient_diagnostic_code: values?.diagnosis_codes,
      clinic_notes: values?.clinical_notes,
      other_notes: values?.internal_notes,
      status: "Signed",
    };
    try {
      const response = await doPost(`/referral/referral-add/${id}/`, data);
      if (response.status === 201) {
        getReferral(id);
        Notification.success("Referral Added Successfully");
        handleCancel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProviderInfo();
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      width={978}
      title="Create Referral"
      footer={null}
      maskClosable={false}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      <NotesHeader showDetails={true} />
      <Form layout="vertical" form={form} onFinish={handleReferral}>
        <h4>Referral From:</h4>
        <p>
          <span className="font-medium">Name: </span>
          {providerInfo?.user}
        </p>
        <p>
          <span className="font-medium">Speciality: </span>{" "}
          {providerInfo?.medical_identifier &&
            providerInfo?.medical_identifier[0]?.primary_speciality[1] &&
            providerInfo?.medical_identifier[0]?.primary_speciality[0]}{" "}
          {providerInfo?.medical_identifier &&
            providerInfo?.medical_identifier[0]?.secondary_speciality[1] &&
            `,  ${providerInfo?.medical_identifier[0]?.secondary_speciality[0]}`}
        </p>
        <p>
          <span className="font-medium">Work Phone: </span>
          {providerInfo?.personel_settings &&
            providerInfo?.personel_settings[0]?.contact?.work_phone}
        </p>
        <p>
          <span className="font-medium">
            Fax:{" "}
            {providerInfo?.personel_settings &&
              providerInfo?.personel_settings[0]?.contact?.fax_number}
          </span>
        </p>
        <p>
          <span className="font-medium">Email: </span>
          {providerInfo?.email}
        </p>
        <Divider />
        <h4>Referring To:</h4>
        <Form.Item
          label="Provider's Name"
          name="referral_to"
          tooltip="Search Referring Provider by Name from Templates"
          rules={[
            {
              required: true,
              message: "Please Choose Referral To Provider",
            },
          ]}
        >
          <AutoComplete
            onSearch={handleSearchReferralDoctor}
            options={referralOptions}
            placeholder="Search for Provider"
            maxLength={100}
            onSelect={onSelect}
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item label="Start Date" name="start_date">
              <BaseDatePicker disabledDate={disabledDate} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Expiry Date" name="end_date">
              <BaseDatePicker disabledDate={disabledDate} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Authorization Code" name="authorization_code">
              <Input placeholder="Authorization Code" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <h4>Patient's Insurance Information:</h4>
        <Form.Item label="Insurance Type" name="insurance_type">
          <Select
            placeholder="Select Insurance Type"
            allowClear
            style={{
              width: 300,
            }}
            onChange={(value) => getInsuranceInfo(value)}
          >
            <Option value="primary">Primary</Option>
            <Option value="secondary">Secondary</Option>
            <Option value="tertiary">Tertiary</Option>
            <Option value="auto">Auto</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>

        {insuranceLoading ? (
          <Loader />
        ) : (
          <>
            <LabelDisplay
              title="Type"
              description={
                insuranceInfo?.insurance_type
                  ? insuranceInfo.insurance_type.charAt(0).toUpperCase() +
                    insuranceInfo.insurance_type.slice(1)
                  : ""
              }
            />
            <LabelDisplay
              title="Company"
              description={insuranceInfo?.insurance_company}
            />
            <LabelDisplay
              title="Plan"
              description={insuranceInfo?.insurance_plan}
            />
            <LabelDisplay
              title="Group"
              description={insuranceInfo?.insurance_group}
            />
            <LabelDisplay
              title="Policy"
              description={insuranceInfo?.insurance_policy}
            />
          </>
        )}
        <Divider />
        <h4>Referral Notes:</h4>
        <Form.Item label="Instructions" name="referral_instructions">
          <Input.TextArea
            placeholder="instructions"
            maxLength={500}
            showCount
          />
        </Form.Item>
        <Form.Item
          label="Reason for Referral"
          name="reason_for_referral"
          rules={[
            {
              required: true,
              message: "Please Enter Reason for Referral",
            },
          ]}
        >
          <Input placeholder="Reason For Referral" maxLength={300} showCount />
        </Form.Item>
        <Form.Item
          label="Search From Patient Diagnosis Codes"
          name="diagnosis_codes"
        >
          <AutoComplete
            onSearch={handleSearchDiagnosis}
            options={options}
            placeholder="Search Diagnosis Code"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          label="Clinical Notes"
          name="clinical_notes"
          rules={[
            {
              required: true,
              message: "Please Enter Clinic Notes",
            },
          ]}
        >
          <Input.TextArea
            placeholder="Clinical Notes"
            maxLength={5000}
            showCount
          />
        </Form.Item>
        <Form.Item
          label="Internal Notes"
          name="internal_notes"
          tooltip="Only visible to you and other office staff members. Will not print on reports"
        >
          <Input.TextArea
            placeholder="Internal Notes"
            maxLength={1000}
            showCount
          />
        </Form.Item>
        <div className="flex justify-end items-center">
          <div>
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save and Sign
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ReferalDrawer;
