import { useCallback, useState } from "react";
import { doGet } from "./apis";
import { useParams } from "react-router-dom";
import FormatDate from "../components/FormatDate/FormatDate";
import { NumberFormatter } from "../utils/validator";
import LabelDisplay from "../components/LabelDisplay/LabelDisplay";

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const searchTime = 300

export const useSearchICD_Codes = () => {
  const [options, setOptions] = useState([]);

  const handleSearchICD10Codes = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(`/icd/icd-code-search/?q=${value}`);
          if (response.status === 200) {
            const filterOptions = response.data.map((item, idx) => {
              const { id, description, code } = item;
              return {
                id,
                value: code,
                description: description,
                label: (
                  <p key={id}>
                    {" "}
                    {code} - {description}
                  </p>
                ),
              };
            });
            setOptions(filterOptions);
          } else {
            setOptions([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { options, handleSearchICD10Codes };
};

export const useSearchServices = () => {
  const [options, setOptions] = useState([]);

  const handleSearchServices = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(`/services/search-services/?q=${value}`);
          if (response.status === 200) {
            const filteredOptions = response.data?.map((service, index) => {
              const { id, service_names } = service;
              return {
                id,
                value: `${service_names}`,
                label: <span key={id}>{service_names}</span>,
              };
            });
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { options, handleSearchServices };
};

export const useSearch = () => {
  const [nameOptions, setNameOptions] = useState([]);

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/appointments/search-patients/?q=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((patient) => {
              const {
                id,
                first_name,
                middle_name,
                last_name,
                date_of_birth,
                gender,
                patient_phone,
                mrn_number,
              } = patient;

              return {
                key: id,
                id: id,
                value: id,
                first_name,
                last_name,
                mrn_number,
                gender,
                date_of_birth,
                patient_phone,
                label: (
                  <div key={id}>
                    <p title={first_name}>
                        {first_name} {middle_name ? middle_name : ""}{" "}
                        {last_name}
                    </p>
                    <p title={date_of_birth}>
                      <FormatDate date={date_of_birth} />
                    </p>
                  </div>
                ),
              };
            });
            setNameOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );

  return { nameOptions, handleSearch };
};

export const useSearchDiagnosis = (check) => {
  const { id } = useParams();
  const [diagnosisOptions, setDiagnosisOptions] = useState([]);

  const handleSearchDiagnosis = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/patient/icd10codes/search/${id}/?q=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((diagnosis) => {
              const { id, code, description } = diagnosis;
              return {
                id,
                value: check ? description : code,
                description: description,
                label: (
                  <div key={id}>
                    <p>Code: {code}</p>
                    <p>Description: {description}</p>
                  </div>
                ),
              };
            });
            setDiagnosisOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { diagnosisOptions, handleSearchDiagnosis };
};

export const useSearchCPT = () => {
  const [CPTCodesOptions, setCPTCodesOptions] = useState([]);

  const handleSearchCPTCodes = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/services/search-cpt-code/?search_term=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((cpt) => {
              const { id, service_description, service_code, fee } = cpt;
              return {
                id,
                value: service_code,
                service_description,
                fee,
                label: (
                  <div key={id}>
                    <p>Code: {service_code}</p>
                    <p title={service_description}>
                      Description: {service_description}
                    </p>
                    <p>Fee: {fee}</p>
                  </div>
                ),
              };
            });
            setCPTCodesOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { CPTCodesOptions, handleSearchCPTCodes };
};

export const useSearchReferralDoctor = () => {
  const [referralOptions, setReferralOptions] = useState([]);

  const handleSearchReferralDoctor = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/referral/referral-doctor-search/?name=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((doctor) => {
              const {
                id,
                full_name,
                phone,
                email,
                speciality,
                fax,
                full_address,
                facility_name,
                operating_hrs,
                referring_provider_notes
              } = doctor;
              return {
                id,
                value: full_name,
                full_name,
                phone,
                email,
                speciality,
                fax,
                full_address,
                facility_name,
                operating_hrs,
                referring_provider_notes,
                label: (
                  <div key={id}>
                    <p>Name: {full_name}</p>
                    <p>Phone: {phone}</p>
                    <p>Email: {email}</p>
                  </div>
                ),
              };
            });
            setReferralOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { referralOptions, handleSearchReferralDoctor };
};

export const useSearchProvider = () => {
  const [providerInfo, setProviderInfo] = useState([]);

  const handleSearchProviderInfo = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/settings/search-provider/?search_param=${value}`
          );
          // console.log('API Response:', response);

          if (response.status === 200) {
            const providerList = response.data?.provider_list;
            // console.log("PROVIDER LIST", providerList)
            if (!providerList) {
              console.error("Error: Provider list is null or undefined");
              return;
            }

            const filteredOptions = providerList.map((provider, index) => {
              const name = provider?.name || "";
              const phone =
                provider?.personel_info?.personel_settings?.phone_number || "";
              const npi = provider?.medical_info?.medical_license?.[0] || "";
              const tax_id = provider?.medical_info?.provider_tax_id?.[0] || "";
              const additional_data_1 = provider?.medical_info
                ?.additional_data_1?.[1]
                && provider?.medical_info?.additional_data_1?.[0]
                ;
              const additional_data_2 = provider?.medical_info
                ?.additional_data_2?.[1]
                && provider?.medical_info?.additional_data_2?.[0]
                ;
              const additional_data_3 = provider?.medical_info
                ?.additional_data_3?.[1]
                && provider?.medical_info?.additional_data_3?.[0]
                

              return {
                value: name,
                phone,
                npi,
                tax_id,
                additional_data_1,
                additional_data_2,
                additional_data_3,
                label: (
                  <div key={index}>
                    <p>Name: {name}</p>
                    <p>Phone: {phone}</p>
                    <p>License: {npi}</p>
                    <p>Tax ID: {tax_id}</p>
                    <p>Data l: {additional_data_1}</p>
                    <p>Data ll: {additional_data_2}</p>
                    <p>Data lll: {additional_data_3}</p>
                  </div>
                ),
              };
            });

            setProviderInfo(filteredOptions);
          } else {
            console.error("Error fetching data: Invalid response status");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );

  return { providerInfo, handleSearchProviderInfo };
};

//search medicines

export const useSearchMedicines = () => {
  const [searchMedicine, setSearchMedicine] = useState([]);

  const handleSearchMedicines = useCallback(
    debounce(async (value) => {
      if (value) {
        setSearchMedicine([]);
        try {
          const response = await doGet(`/prescriptions/search/?q=${value}`);
          if (response.status === 200) {
            const filteredOptions = response.data?.data?.map(
              (product, idx) => ({
                value: `${product?.drug_name}`,
                label: (
                  <div key={idx}>
                    {product?.name_type} - {product?.drug_name}
                  </div>
                ),
              })
            );
            setSearchMedicine(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setSearchMedicine([]); // Set options to an empty array when value is empty
      }
    }, searchTime),
    []
  );

  return { searchMedicine, handleSearchMedicines };
};

export const useAppointmentSearchServices = () => {
  const [options, setOptions] = useState([]);

  const handleSearchServices = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(`/services/search-services/?q=${value}`);
          if (response.status === 200) {
            const filteredOptions = response.data?.map((service, index) => {
              const {
                id,
                service_names,
                service_type,
                service_group_name,
                price,
              } = service;
              return {
                id,
                value: `${service_names}-${service_group_name}`,
                service_name: service_names,
                price: price,
                service_type: service_type,
                service_group: service_group_name,
                label: (
                  <div key={id}>
                    <div className="flex justify-between">
                      <p>
                        <span className="font-medium">Name: </span>{" "}
                        {service_names}
                      </p>
                      <p>
                        <span className="font-medium">Type: </span>{" "}
                        {service_type}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p>
                        <span className="font-medium">Group: </span>{" "}
                        {service_group_name}
                      </p>
                      <p>
                        <span className="font-medium">Price: </span> {price}
                      </p>
                    </div>
                  </div>
                ),
              };
            });
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { options, handleSearchServices };
};

export const useSearchLabs = () => {
  const [options, setOptions] = useState([]);

  const handleSearchLabs = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/labs/images/service/search/?q=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((lab, index) => {
              const { id, service_names } = lab;
              return {
                id,
                value: `${service_names}`,
                label: <div key={id}>{service_names}</div>,
              };
            });
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { options, handleSearchLabs };
};

export const useSearchProduct = (showStrength = true) => {
  const [options, setOptions] = useState([]);

  const handleSearchProduct = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(`/inventory/search/?q=${value}`);
          if (response.status === 200) {
            const filteredOptions = response.data?.map((productName) => ({
              key: productName?.id,
              id: productName?.id,
              value: showStrength ? `${productName?.medicine_name}-${productName?.strength}`: productName?.medicine_name,
              price: productName?.sale_price,
              quantity: productName?.quantity,
              strength: productName?.strength,
              label: (
                <div key={productName?.id}>
                  <div className="flex justify-between">
                  <div>
                    <LabelDisplay
                      title="Brand Name"
                      description={
                        productName?.medicine_name?.substring(0, 15) ||
                        "Not Available"
                      }
                    />
                    <LabelDisplay
                      title="Strength"
                      description={
                        productName?.strength?.length > 0
                          ? productName?.strength
                          : "Not Added"
                      }
                    />
                    <LabelDisplay
                      title="Generic Name"
                      description={
                        productName?.generic_name?.substring(0, 15) ||
                        "Not Available"
                      }
                    />
                  </div>
                  <div>
                    <LabelDisplay
                      title="Quantity"
                      description={
                        <span className="font-medium">
                          {productName?.quantity || "0"}
                        </span>
                      }
                    />
                    <LabelDisplay
                      title="Price"
                      description={
                        <span className="font-medium">
                          <NumberFormatter
                            value={productName?.sale_price}
                            options={{ style: "decimal" }}
                          />
                        </span>
                      }
                    />
                    <LabelDisplay
                      title="SKU"
                      description={
                        <span className="font-medium">
                          {productName?.sku || "Not Available"}
                        </span>
                      }
                    />
                  </div>
                  </div>
                  <div>
                    <LabelDisplay
                      title="Notes"
                      description={
                        <span className="font-medium">
                          {productName?.notes || "No Notes"}
                        </span>
                      }
                    />
                  </div>
                </div>
              ),
            }));
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          Notification.error("Something Went Wrong");
        }
      } else {
        setOptions([]);
      }
    }, searchTime),
    []
  );
  return { options, handleSearchProduct };
};


export const useSearchAddedLabs = () => {
  const [options, setOptions] = useState([]);

  const handleSearchLabs = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/labs/images/service/search/?q=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((lab, index) => {
              const { id, service_names, service_group_name, service_type, price } = lab;
              return {
                key: id,
                lab_name: service_names,
                service_group: service_group_name,
                service_type: service_type,
                price: price,
                value: `${service_names}-${id}`,
                label: (
                  <div key={lab?.idx} className="flex justify-between">
                  <p>
                    <span className="font-medium">Name: </span>{" "}
                    <span>{service_names} </span>
                  </p>
                  <p>
                    <span className="font-medium">Type: </span>{" "}
                    <span>{service_type} </span>
                  </p>
                  <p>
                    <span className="font-medium">Group: </span>{" "}
                    <span>{service_group_name} </span>{" "}
                  </p>
                  <p>
                    <span className="font-medium">Price: </span>
                    <span>{price} </span>
                  </p>
                </div>
                ),
              };
            });
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { options, handleSearchLabs };
};

export const useSearchPatient = (getNameValue) => {
  const [options, setOptions] = useState([]);

  const handleSearchPatient = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/appointments/search-patients/?q=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((patient, index) => {
          const {
            id,
            first_name,
            middle_name,
            last_name,
            date_of_birth,
            gender,
            patient_phone,
            mrn_number,
            profile_picture,
          } = patient;
          const fullName = [first_name, middle_name, last_name].filter(Boolean).join(" ");
          const nameValue = getNameValue({
            first_name,
            middle_name,
            last_name,
            fullName,
          });

              return {
            key: id,
            id: id,
            value: nameValue,
            first_name,
            middle_name,
            last_name,
            mrn_number,
            gender,
            date_of_birth,
            patient_phone,
            profile_picture,
            label: (
              <div key={id}>
                <p title={first_name}>
                  {first_name} {middle_name} {last_name}
                </p>
                <p title={date_of_birth}>
                  <FormatDate date={date_of_birth} />
                </p>
              </div>
            ),
              };
            });
            setOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }, searchTime),
    []
  );
  return { options, handleSearchPatient };
};

