import React, { useState, useCallback } from "react";
import { Form, Input, Button, AutoComplete, Row, Col, Card, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { doPost, doGet } from "../../../../../API/apis";
import { useParams } from "react-router-dom";
import Notification from "../../../../../components/Notification/Notification";
import showConfirmModal from "../../../../../components/ModalConfirm/ModalConfirm";
import { useSearchAddedLabs } from "../../../../../API/apiUtils";

function ExternalLabs() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [lab, setLab] = useState({
    lab_name: "",
    service_type: "",
    service_group: "",
  });
  const [edit, setEdit] = useState(false);
  const [diagnosisOptions, setDiagnosisOptions] = useState([]);
  const [labsList, setLabsList] = useState([]);
  const [editingItemKey, setEditingItemKey] = useState(null);
  const { options, handleSearchLabs } = useSearchAddedLabs();

  const onSelect = (value, option) => {
    const selectedLab = options.find((item) => item.value === value);
    if (selectedLab) {
      setLab({
        lab_name: selectedLab?.lab_name,
        service_type: selectedLab?.service_type,
        service_group: selectedLab?.service_group,
      });
    }
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleDiagnosisSearch = useCallback(
    debounce(async (value) => {
      if (value) {
        try {
          const response = await doGet(
            `/patient/icd10codes/search/${id}/?q=${value}`
          );
          if (response.status === 200) {
            const filteredOptions = response.data?.map((productName) => ({
              value: productName?.description,
            }));
            setDiagnosisOptions(filteredOptions);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          Notification.error("Error fetching data");
        }
      } else {
        setDiagnosisOptions([]);
      }
    }, 300),
    []
  );

  const handleExternalLabs = (values) => {
    const newLabs = {
      ...values,
      lab_name: lab?.lab_name,
      service_type: lab?.service_type,
      service_group: lab?.service_group,
      key: Date.now(),
    };
    setLabsList([...labsList, newLabs]);
    form.resetFields();
    setEdit(false);
  };

  const handleEdit = (item) => {
    form.setFieldsValue({
      lab_name: item.lab_name,
      lab_instructions: item.lab_instructions,
      lab_diagnosis: item.lab_diagnosis,
    });
    setEdit(true);
    setEditingItemKey(item.key);
  };

  const handleUpdate = (values) => {
    const updatedLabsList = labsList.map((item) =>
      item.key === editingItemKey ? { ...item, ...values } : item
    );

    setLabsList(updatedLabsList);
    form.resetFields();
    setEdit(false);
    setEditingItemKey(null); // Reset the editing item key
  };

  const handleDelete = (item) => {
    showConfirmModal({
      title: "Confirm Delete?",
      icon: null,
      content: "Are you sure you want to delete this?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-modal",
      onOk() {
        const updatedLabsList = labsList.filter((lab) => lab.key !== item.key);
        setLabsList(updatedLabsList);
      },
    });
  };

  const handleFormClear = () => {
    setEdit(false);
    form.resetFields();
  };

  const handleClear = () => {
    showConfirmModal({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        setLabsList([]);
        setEdit(false);
      },
    });
  };

  const handleExternalLabOrders = async () => {
    const data = {
      lab_name: labsList,
    };
    try {
      const response = await doPost(
        `/labs/images/create/${id}/?q=external`,
        data
      );
      if (response.status === 201) {
        Notification.success("External Lab Orders Created successfully");
        setLabsList([]);
      }
    } catch (error) {
      Notification.error("Something went Wrong");
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={24} lg={24} xl={12}>
        <Card className="shadow-class" title="External Labs">
          <Form
            layout="vertical"
            name="external-lab"
            form={form}
            onFinish={edit ? handleUpdate : handleExternalLabs}
          >
            <Form.Item
              name="lab_name"
              tooltip="Name, Group, Type, Price"
              label="Search External Labs"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter Lab name",
                },
              ]}
            >
              <AutoComplete
                options={options}
                allowClear={true}
                onSelect={onSelect}
                onSearch={handleSearchLabs}
                placeholder="Search Inhouse Labs Only"
                maxLength={100}
              />
            </Form.Item>
            <Form.Item name="lab_instructions" label="Lab Instructions">
              <Input.TextArea
                placeholder="Enter Instructions here"
                rows={4}
                maxLength={500}
                showCount
              />
            </Form.Item>
            <Form.Item name="lab_diagnosis" label="Diagnosis">
              <AutoComplete
                onSearch={handleDiagnosisSearch}
                options={diagnosisOptions}
                placeholder="Search Associated Diagnosis"
                maxLength={300}
              />
            </Form.Item>
            <div className="flex justify-end">
              <Button onClick={handleFormClear}>Clear</Button>
              <Button className="ml-2" type="primary" htmlType="submit">
                {edit ? "Update Lab" : "Add to List"}
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
      <Col xs={24} md={24} lg={24} xl={12}>
        <Card
          className="shadow-class"
          title="External Labs Pad"
          extra={
            <Space>
              {labsList.length !== 0 && (
                <Button type="link" onClick={handleClear}>
                  Clear All
                </Button>
              )}
            </Space>
          }
        >
          <div className="mb-2">
            <ul>
              {labsList.map((item) => (
                <li key={item.key} className="flex justify-between">
                  <span>{item.lab_name}</span>
                  <div>
                    <EditOutlined
                      className="text-blue-400"
                      onClick={() => {
                        handleEdit(item);
                      }}
                    />
                    <DeleteOutlined
                      className="ml-2 mr-4 text-red-600"
                      onClick={() => {
                        handleDelete(item);
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex justify-end mb-4">
            <Button
              type={labsList.length === 0 ? "default" : "primary"}
              disabled={labsList.length === 0}
              onClick={handleExternalLabOrders}
            >
              Order
            </Button>
          </div>
          <p className="text-gray-400 text-center">
            Invoice will NOT be created for External Lab Orders.
          </p>
        </Card>
      </Col>
    </Row>
  );
}

export default ExternalLabs;
